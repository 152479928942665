const prefix = '/users'
export const USERS = `${prefix}`
export const suspendUserById = (userId: string) => {
  return `${prefix}/${userId}/suspend`
}
export const activateUserById = (userId: string) => {
  return `${prefix}/${userId}/activate`
}

export const deleteUserById = (userId: string) => {
  return `${prefix}/${userId}`
}

export const updateUserById = (userId: string) => {
  return `${prefix}/${userId}`
}

export const fetchReferentUsers = () => {
  return `${prefix}/referent`
}

export const fetchReferredUsers = (userId?: string) => {
  return `${prefix}/referred/${userId}`
}

export const addReferredUserId = () => {
  return `${prefix}/add-referred-user`
}

export const userLikedAlbumsById = (userId: string) => {
  return `${prefix}/${userId}/liked-albums`
}

export const userStarredAlbumsById = (userId: string) => {
  return `${prefix}/${userId}/starred-albums`
}
