import axios from './axios'
import exifr from 'exifr'

class FileService {
  public parseMetadata = async (
    file: File
  ): Promise<{ width: number; height: number; takenDate: Date; extraInfo: any } | null> => {
    const reader = new FileReader()
    if (file) {
      reader.readAsDataURL(file)
      try {
        const info = await exifr.parse(file)
        const takenDate = info.DateTimeOriginal
        const width = info.ExifImageWidth
        const height = info.ExifImageHeight
        return {
          width,
          height,
          takenDate,
          extraInfo: info,
        }
      } catch (e) {
        console.error(JSON.stringify(e))
        return null
      }
    } else {
      throw new Error('Error extracting file. No file provided.')
    }
  }

  async uploadFileToUrl(
    image: File,
    uploadUrl: string,
    onUploadProgress: (e: any) => void,
    onError: (e: any) => void
  ): Promise<string> {
    try {
      const response = await axios.put(uploadUrl, image, {
        headers: {
          'Content-Type': image.type,
        },
        onUploadProgress,
      })
      if (response.status === 200) {
        return 'File uploaded successfully'
      } else {
        throw new Error(`Failed to upload file: ${response.statusText}`)
      }
    } catch (e) {
      onError(e)
      throw e
    }
  }
}

export default FileService
